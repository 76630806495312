import Link from "next/link";

export default function FourOhFour() {
  return (
    <div className="exception">
      <div className="exception-brand">
        <Link href="/">
          <img src={"icons/logomouse.svg"} alt="" />
        </Link>
      </div>

      <div className="exception-wrapper">
        <h1 className="exception-title">404</h1>
        <div className="exception-content-text">Страница не найдена</div>

        <Link href="/">
          <div className="exception-content-button">
            Вернуться на главную страницу
          </div>
        </Link>
      </div>
    </div>
  );
}
